.dot_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dot {
    animation: dot_animation 1.5s infinite ease-in-out;
    background-color: #253bde;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    margin: 0 0.15rem;
  }
  .dot:nth-child(2) {
    animation-delay: 0.5s;
  }
  .dot:nth-child(3) {
    animation-delay: 1s;
  }
  .dot:nth-child(4) {
    animation-delay: 1.5s;
  }
  .dot:nth-child(5) {
    animation-delay: 2s;
  }
  @keyframes dot_animation {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
    25% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }
    50% {
      opacity: .4;
      transform: scale(1, 1);
    }
    75% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
  }